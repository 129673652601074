import React from "react";
import { Link } from "gatsby";

const Pager = ({ pageContext, isBlogPage, pathname, numberOfPages }) => {
  const { previousPagePath, nextPagePath, humanPageNumber } = pageContext;

  let items = [];

  for (var i = 0; i < numberOfPages; i++) {
    items.push(i + 1);
  }

  const previousArrow = previousPagePath ? (
    <li className={previousPagePath ? "page" : "page disabled"}>
      <Link to={previousPagePath}>⟨</Link>
    </li>
  ) : null;

  const lastPage = numberOfPages !== humanPageNumber;
  const nextArrow = lastPage ? (
    <li className={nextPagePath ? "page" : "page disabled"}>
      <Link to={nextPagePath}>⟩</Link>
    </li>
  ) : null;

  return (
    <div className="react-pagination-js-default">
      <ul>
        {previousArrow}
        {items.map((item, ix) => {
          const path1 = isBlogPage
            ? `${pageContext.lang === "en" ? "/en/blog/" : "/blog/"}`
            : `${pathname}`;
          const path2 = isBlogPage
            ? `${
                pageContext.lang === "en" ? `/en/blog/${item}` : `/blog/${item}`
              }`
            : `${pathname}${item}/`;
          return (
            <li
              className={
                humanPageNumber === Number(item) ? "is-active" : "page"
              }
              key={ix}
            >
              {item === 1 && <Link to={path1}>{item}</Link>}
              {item !== 1 && <Link to={path2}>{item}</Link>}
            </li>
          );
        })}
        {nextArrow}
      </ul>
    </div>
  );
};

export { Pager };
